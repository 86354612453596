<template>
    <div>
        <vx-card>
            <vs-row>
                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                    <h3>Equipe:</h3>
                    <vs-button color="success" type="filled" v-on:click="addEquipe" icon-pack="feather" icon="icon-plus" >Ajouter</vs-button>
                </vs-col>
            </vs-row>
            <vs-divider/>
            <!-- table -->
            <vs-table pagination max-items="20" search :data="tab_member">
                
                <!-- header table -->
                <template slot="thead">
                    <vs-th sort-key="first_name">Prénom Nom</vs-th>
                    <vs-th sort-key="phone">Téléphone</vs-th>
                    <vs-th sort-key="email">Email</vs-th>
                    <vs-th sort-key="type">Rôle</vs-th>
                    <vs-th sort-key="active">Statut</vs-th>
                </template>

                <!--Body table-->
                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                        <vs-td :data="data[indextr].first_name">
                            <span v-on:click="selectMbr(data[indextr].uid)">{{ StrCapital(data[indextr].first_name+' '+data[indextr].last_name) }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].phone">
                            <span v-on:click="selectMbr(data[indextr].uid)">{{ getPhoneClean(data[indextr].phone) }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].email">
                            <span v-on:click="selectMbr(data[indextr].uid)">{{ data[indextr].email }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].type">
                            <span v-on:click="selectMbr(data[indextr].uid)">{{ getAuthName( data[indextr] ) }}</span>
                        </vs-td>
                        <vs-td :data="data[indextr].active">
                            <span v-on:click="selectMbr(data[indextr].uid)">{{ getEtatName( data[indextr] ) }}</span>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>
        <AddEquipe ref="addEquipe" v-on:refresh="refresh()" />
    </div>
</template>



<style lang="scss">
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'
import Member from '@/database/models/member'
import Phone from '@/assets/utils/phone'
import Utils from '@/assets/utils/utils'

import AddEquipe from '@/components/member/adde.vue'

export default {
    components:{
        AddEquipe,
	},
    data(){
        return{
            tab_member:[],
        }
	},
	mounted:function()
	{
        //autoRefresh
        autoRefresh.SetCallback(async()=>
        {
            this.tab_member = [];
			Member.getTabAllMemory(async (input_tab)=>
			{
                for( var i=0; i<input_tab.length; i++ )
                {
                    switch(  input_tab[i].type )
                    {
                        case 'a':
                        case 'u':
                        case 'c':
                            this.tab_member.push( input_tab[i] );
                        break;
                    }
                }
            })
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            
        }
    },
    methods:
    {
		getAuthName( mbr )
		{
			return Member.getAuthName(mbr);
        },
        getEtatName( mbr )
		{
			return Member.getEtatName(mbr);
        },
        getPhoneClean( phone )
		{
			return Phone.getLocalPhone( phone );
		},
		StrCapital( msg )
		{
			return Utils.StrCapital(msg);
        },
        selectMbr( uid )
		{
			this.$router.push('/admin/member/'+uid);
        },
        

        addEquipe()
        {
            this.$refs.addEquipe.openPopup();
        },
        refresh()
        {
           autoRefresh.refresh() 
        }
    }
};

</script>